<template>
    <div class="mb-0">
      <card class="mb-0">
        <h5 slot="header" class="title mb-0">{{state.id ? 'Update' : 'Add'}} State</h5>
        <v-form
            ref="form"
            v-model="parentForm"
            @submit.enter.prevent
        >
       
        <div class="row ma-0 pa-0">
          
          <div class="col-md-12 px-0">
            <v-text-field
              placeholder="State Name"
              v-model="state.name"
              :rules="nameRules"
              outlined
              label="State Name"
            >
            </v-text-field>
          </div>
        </div>
        <v-row class="pb-5 px-3">
            <v-col cols="auto" class="d-flex ml-auto pa-0 pt-2">
        <v-btn
            class="body-2 font-weight-bold mr-5 cancel-color"
            @click="cancel()"
        >
            Cancel
        </v-btn>
        <v-btn
            class="body-2 font-weight-bold save-color"
            :loading="editloading"
            :disabled="!parentForm"
            :dark="parentForm"
            @click="updateInfo()"
        >
            {{state.id  ? 'update' : 'save'}}
        </v-btn>
    </v-col>
    </v-row>
    </v-form>
      </card>
    </div>
  </template>
  <script>
  import commonService from '@/services/commonFunction.js'
  import apiService from '@/services/apiServices.js'
  export default {
    name:'state',
    props: {
        state: {
        type: Object,
        default: () => {
          return {};
        }
      },
      refreshData:{
        type: Function,
        default : () => null
      },
      cancel:{
        type: Function,
        default : () => null
      }
    },
    data(){
      return {
        parentForm:null,
        editloading:false,
        nameRules:[
            v => !!v || 'Name is required',
            v => !v || !!v.trim() || 'Name can not be blank'
        ]
      }
    },
    methods:{
        async updateInfo(){
            let payload = {
                state: { 
                    name: this.state.name,
                    countryId: 1
                }
            }
            this.editloading = true
            let res;
            try{
                if(this.state.id){
                    res = await apiService.updateState(payload,this.state.id)        
                }
                else 
                    res = await apiService.addState(payload)      

                commonService.alert(`State ${this.state.id ? 'updated' : 'added'} successfully`, 'Success')
                this.editloading = false;
                this.refreshData();
                this.cancel();
            }
            catch(err){
                console.log(err);
                this.editloading = false
            }
        
      }
    }
  };
  </script>
  <style scoped>
  .cancel-color{
        background-color: grey !important;
    }
    .save-color{
        background-color: #2e4250 !important
    }
  </style>
  
  