<template>
    <div class="login-page col-md-4 col-12">
        <card type="user" class="login-card">
            <div class="col-md-12 col-12 admin-text">
                <img class="logo" src="../../assets/images/logo.png">
            </div>
            <div class="col-md-12 col-12">
            <base-input
                label="Password"
                type="password"
                placeholder="password"
                v-model="user.password"
                >
                </base-input>
            </div>
            <div class="col-md-12 col-12">
                <base-input
                label="Confirm Password"
                type="password"
                placeholder="Confirm Password"
                v-model="user.cpassword"
                >
                </base-input>
            </div>
            <div class="col-md-12 col-12 login-btn">
            <base-button :loading="loading" :disabled="!btndisabled" @click="login()" type="primary"  fill>Reset Password</base-button>
        </div>
        </card>
        <div class="copy-right"> Imbey© 2023-24 Imbey All rights reserved </div>
    </div>
</template>

<script>
import apiService from '@/services/apiServices.js'
import commonService from '@/services/commonFunction.js'
export default{
    name: 'resetPassword',
    data () {
        return {
            loading:false,
            user : {
            }
        }
    },
    computed:{
        btndisabled(){
            return !!(this.user.cpassword && this.user.password)
        }
    },
    methods : {
        login (){
            if(this.user.cpassword != this.user.password){
                commonService.alert('Both passwords do not match', 'error','error',{icon:'error'})
                return;
            }
            let token = this.$route.query.token
            if(!token){
                commonService.alert('Reset Token does not exist', 'error','error',{icon:'error'})
                return;
            }
            this.loading = true;
            let data = '';
            let join = '';

            function addParam(name, value)
            {
                if(value == undefined) return;
                data += join + name + '=' + encodeURIComponent(value);
                join = '&';
            }

            addParam("cpassword",this.user.cpassword);
            addParam("password",this.user.password);
            addParam("token",token);
            
            apiService.resetPassword(data).then(res=>{
                if(res){
                    commonService.alert(res.message, 'success','success',{icon:'success'})
                    this.$router.push({name:'login'})
                }
            })
            .finally(()=>{
                this.loading = false
            })
        }
    }
}
</script>

<style scoped>
.login-btn{
    text-align: center;
    margin-top: 20px;
}
.forgot-password{
    text-align: end;
    cursor: pointer;
    color: #525f7f !important;
}
.forgot-password:hover{
    color: black;
}
.admin-text{
    text-align: center;
}
.logo{
    width:100px;
    margin-top:20px;
}
.copy-right{
    position: fixed;
    bottom: 20px;
    width: 100%;
    text-align: center;
    left: 0;
}
.login-page{
    margin:0px auto;
}
.login-card{
    margin-top: calc(50% - 30px);
}
</style>