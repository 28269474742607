<template>
    <v-container>
        <header>
            <v-row class="ma-0 pa-0">
                <v-col cols="3" class = "pl-0" >
                    <h2 class="mb-0">All States ({{ totalItems }})</h2>
                </v-col>
                <v-col cols="auto" class="d-flex ml-auto pa-0 pt-2">
                    <v-btn
                        class="mr-3 save-color"
                        dark
                        link
                        @click="addNew()"
                    >
                        Add New
                    </v-btn>
                </v-col>
            </v-row>
        </header>
        <v-data-table
            ref="tableRef"
            :headers="headers"
            :items="states"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            :server-items-length="totalItems"
            :options.sync="pagination"
            :loading="loading"
            loading-text="Loading... Please wait"
            class="elevation-1"
            :footer-props="{
                'items-per-page-options': [5, 10, 25, 50, 100]
            }"
        >
            <template v-slot:item="{ item }">
                <tr>
                    <td>{{ item.name }}</td>
                    <td> 
                        <actionMenu
                        :key="item.id"
                        :menu-items="getMenu()"
                        :item="item"
                      />
                    </td>
                </tr>
            </template>
        </v-data-table>   
        <v-dialog
            v-model="editStatePopup"
            width="700"
            overlay-color="#9e9e9e"
            content-class="dialog-overflow"
            transition="dialog-bottom-transition"
            @click.outside="hidePopup"
        >
            <state v-if="deletModel" 
                ref="model" 
                :refresh-data="getAllStates"
                :state="{...stateData}" 
                :cancel="hidePopup"
            >
            </state>
            <delete-popup v-else 
                ref="model" 
                title="state" 
                :title-name="stateData.name"  
                :cancel="hideDeletePopup"
                :id="stateData.id"
                :refresh-data="getAllStates"
                :delete-method="deleteApi"
            >
            </delete-popup>
        </v-dialog> 
        <v-overlay :value="loading">
            <v-progress-circular
                indeterminate
                size="64"
            />
        </v-overlay>
    </v-container>
</template>

<script>
import actionMenu from '@/components/TableActionMenu'
import commonFunctions from '@/services/commonFunction.js'
import apiService from '@/services/apiServices.js'
import state from '@/components/Dialogs/state.vue'
import deletePopup from '@/components/Dialogs/delete.vue'
export default{
  name : 'States',
  components: {
      actionMenu,
      state,
      deletePopup
  },
  data(){
      return {
          page:1,
          itemsPerPage:10,
          totalItems:0,
          pagination:{ sortDesc: [true] },
          loading:false,
          states:[],
          stateData:{},
          editStatePopup:false,
          deletModel:false,
          searchString:'',
          headers: [
              { text: "Name", align: "left", value: "name ", sortable: false,width: "95%" },
              { text: "Actions",value: "action",sortable: false,align: "left",width: "40px",menuOption:this.getMenu()}
          ],
      }
  },
  watch:{
      pagination: {
      handler () {
        this.getAllStates()
      },
      deep: true,
    },
    $route(to,from){
        this.pagination.page=1;
        this.searchString = to.query.keyword 
        this.getAllStates()
    }
  },
  computed:{
    deleteApi(){
        return apiService.deleteState;
    }
  },
  methods:{
      enableTop (id) {
          return commonFunctions.calculateTop(id, this.states)
      },
      enableAttach (id){
          return commonFunctions.enableAttach(id, this.states)
      },
      getMenu(){
          return {
              menu : {
                  enableAttach : this.enableAttach,
                  enableTop : this.enableTop,
                  elementType : "states",
                  list : [
                  {
                      id : 1,
                      icon : 'mdi-pencil',
                      value : 'Edit',
                      type : 'button',
                      buttonClick : (item)=>{
                        this.stateData = item
                        this.deletModel = true;
                        this.editStatePopup = true;
                      }
                  },
                  {
                      id : 2,
                      icon : 'mdi-delete',
                      value : 'Delete',
                      type : 'button',
                      buttonClick : (item)=>{
                        this.stateData = item
                        this.deletModel = false;
                        this.editStatePopup = true;
                      }
                  }
                  ]
              }
          }
      },
      editState(item){
          this.$router.push({
                    path:"/user/"+item.id
                  })
      },
      getAllStates(){
          const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
          const sort = sortDesc.length ? (sortDesc[0] === true ? 'desc' : 'asc') : ''
          const sortMethod = sortBy.length ? sortBy[0] : []
        
          const payload = {
              page: page,
              itemsPerPage: itemsPerPage,
          };

          if(this.searchString)
            payload.keyword = this.searchString
          
        //   if (sortMethod) { payload.method = sortMethod }
        //   if (sort) { payload.sort_by = sort }

         this.loading = true;

          apiService.getStates({params:payload}).then(res=>{
              this.totalItems = res.description.length
              this.states = res.description
          })
          .finally(()=>{
                this.loading = false;
            })
      },
      addNew(){
          this.stateData = {}
          this.deletModel = true;
          this.editStatePopup = true;
      },
      hidePopup(){
        this.$refs.model.$refs.form.resetValidation();
        this.stateData = {}
        this.editStatePopup = false;
      },
      hideDeletePopup(){
        this.stateData = {}
        this.editStatePopup = false;
      }

  }
}
</script>

<style scoped>
.justify-center{
  display: grid;
  align-items: center;
}
.save-color{
        background-color: #2e4250 !important
    }
</style>
<style>
.dialog-overflow{
    overflow: visible !important;
}
</style>