<template>
    <div class="login-page col-md-4 col-12">
        <card type="user" class="login-card">
            <div class="col-md-12 col-12 admin-text">
                <img class="logo" src="../../assets/images/logo.png">
            </div>
            <template v-if="otpNotSent">
                <div class="col-md-12 col-12">
                    <base-input
                        label="Username"
                        type="text"
                        placeholder="Username"
                        v-model="user.usr"
                    >
                    </base-input>
                </div>
                <div class="col-md-12 col-12">
                    <base-input
                        label="Password"
                        type="password"
                        placeholder="Password"
                        v-model="user.pwd"
                    >
                    </base-input>
                    <router-link
                        :to="{name:'forgot'}"
                    >
                        <div class="forgot-password">Forgot Password</div>
                    </router-link>
                </div>
            </template>
            <template v-else>
                <div class="col-md-12 col-12">
                    <base-input
                        label="OTP"
                        type="text"
                        placeholder="OTP"
                        v-model="user.otp"
                    >
                    </base-input>
                </div>
            </template>
            <div class="col-md-12 col-12 login-btn">
                <base-button :loading="loading" :disabled="!btndisabled" @click="login()" type="primary"  fill>Login</base-button>
        </div>
        </card>
        <div class="copy-right"> Imbey© 2023-24 Imbey All rights reserved </div>
    </div>
</template>

<script>
import apiService from '@/services/apiServices.js'
import authService from '@/services/AuthService.js'
import commonService from '@/services/commonFunction.js'
export default{
    name: 'Login',
    data () {
        return {
            loading:false,
            otpNotSent:true,
            user : {
                admin:true
            }
        }
    },
    computed:{
        btndisabled(){
            return !!(this.user.usr && this.user.pwd)
        }
    },
    methods : {
        login (){
            this.loading = true;
            let data = {
                signin : {...this.user}
            };
            
            apiService.login(data).then(res=>{
                if(!this.otpNotSent){
                    authService.setUser(res);
                    this.$router.push({name:'users'})
                    return
                }    
                commonService.alert(res.description, 'Success')
                this.otpNotSent = false
                    
            })
            .finally(()=>{
                this.loading = false
            })
        }
    }
}
</script>

<style scoped>
.login-btn{
    text-align: center;
    margin-top: 20px;
}
.forgot-password{
    text-align: end;
    cursor: pointer;
    color: #525f7f !important;
}
.forgot-password:hover{
    color: black;
}
.admin-text{
    text-align: center;
}
.logo{
    width: 100px;
    margin-top: 20px;
}
.copy-right{
    position: fixed;
    bottom: 20px;
    width: 100%;
    text-align: center;
    left: 0;
}
.login-page{
    margin:0px auto;
}
.login-card{
    margin-top: calc(50% - 30px);
}
</style>