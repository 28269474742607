<template>
    <div class="login-page col-md-4 col-12">
        <card type="user" class="login-card">
            <div class="col-md-12 col-12 admin-text">
                <img class="logo" src="../../assets/images/logo.png">
            </div>
            <div class="col-md-12 col-12">
            <base-input
                label="Email"
                type="email"
                placeholder="Enter your email"
                v-model="user.email"
                >
                </base-input>
                <router-link
                :to="{name:'login'}"
                >
                    <div class="forgot-password">Login</div>
                </router-link>
            </div>
            <div class="col-md-12 col-12 login-btn">
            <base-button :loading="loading" :disabled="!btndisabled" @click="login()" type="primary"  fill>Submit</base-button>
        </div>
        </card>
        <div class="copy-right"> Imbey© 2023-24 Imbey All rights reserved </div>
    </div>
</template>

<script>
import apiService from '@/services/apiServices.js'
import commonService from '@/services/commonFunction.js'
export default{
    name: 'Login',
    data () {
        return {
            loading:false,
            user : {
            }
        }
    },
    computed:{
        btndisabled(){
            return !!(this.user.email)
        }
    },
    methods : {
        validateEmail(email) {
            return email.match(
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
        },
        login (){
            if(!this.validateEmail(this.user.email)){
                commonService.alert('email is incorrect', 'error','error',{icon:'error'})
                return;
            }
            this.loading = true
            let data = {
                email:this.user.email
            };
            
            
            apiService.forgot(data).then(res=>{
                commonService.alert('Please check your email.', 'success','success',{icon:'success'})
                this.$router.push({name:'login'})
            }).finally(()=>{
                this.user.email= ''
                this.loading = false
            })
        }
    }
}
</script>

<style scoped>
.login-btn{
    text-align: center;
    margin-top: 20px;
}
.forgot-password{
    text-align: end;
    cursor: pointer;
    color: #525f7f !important;
}
.forgot-password:hover{
    color: black;
}
.admin-text{
    text-align: center;
}
.logo{
    width:100px;
    margin-top: 20px;
}
.copy-right{
    position: fixed;
    bottom: 20px;
    width: 100%;
    text-align: center;
    left: 0;
}
.login-page{
    margin:0px auto
}
.login-card{
    margin-top: calc(50% - 30px);
}
</style>